<script>
import form from './form'

export default {
  extends: form,

  methods: {
    onSubmit() {
      return this.$apiMethods.stores.create({ ...this.form, ...this.getCoords, is_external: true })
        .then(() => {
          this.successSubmit({ message: 'Склад успешно создан', routeName: 'pc-stores' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>